<template>
  <v-app>
    <div class="align-center" style="background-color: #dcf1ef; height: 500px">
      <v-container grid-list-xs fill-height fluid>
        <v-row>
          <v-col>
            <div
              :class="{
                'text-center display-1 accent--text font-weight-bold':
                  $vuetify.breakpoint.smAndDown,
                ' font-weight-bold text-center display-3 accent--text':
                  $vuetify.breakpoint.mdAndUp,
              }"
            >
              <span style="font-family: Helvetica; color: #398860">
                {{ $t("immediateUse") }}
              </span>
              {{ $t("yourConferenceApp") }}
            </div>
            <br />
            <h3
              :class="{
                'text-center headline accent--text':
                  $vuetify.breakpoint.smAndDown,
                'text-center display-1 accent--text':
                  $vuetify.breakpoint.mdAndUp,
              }"
              style="color: white"
            >
              {{ $t("withBrowser") }}
            </h3>
            <br />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container grid-list-xs>
      <div class="d-flex align-center">
        <div class="container bloc-lg align-center ma-4" style="height: 350px">
          <v-row>
            <v-col cols="12" md="6" class="order-md-1">
              <img
                src="../assets/svg/web.svg"
                class="img-fluid mx-auto d-block"
                width="100%"
                alt="rollCall"
              />
            </v-col>
            <v-col cols="12" md="6" class="align-self-center">
              <div style="color: #398860">
                <h1 class="mg-md float-lg-none tc-mint mb-4">
                  {{ $t("lightWeight") }}
                </h1>
              </div>
              <span class="title">
                {{ $t("noInstall") }}
              </span>
              <br />
              <span class="title">
                {{ $t("browserOnly") }}
              </span>
              <br />
              <span class="title">
                {{ $t("offLine") }}
              </span>
              <br />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>

    <v-container fluid style="background-color: #dcf1ef">
      <v-container grid-list-xs>
        <v-row justify="center">
          <v-col cols="12">
            <v-carousel height="100%">
              <v-carousel-item
                v-for="(item, i) in functions"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col class="text-center">
            <div style="color: #398860">
              <h1 class="mg-md tc-mint mb-4">
                {{ $t("satisfyRequirements") }}
              </h1>
            </div>
            <span class="title my-4">
              {{ $t("importCountries") }}
            </span>
            <br />
            <span class="title my-4">
              {{ $t("speakingManage") }}
            </span>
            <br />
            <span class="title my-4">
              {{ $t("voteCalculation") }}
            </span>
            <br />
            <br />
            <v-btn class="my-4" color="primary" outlined to="/setup">
              {{ $t("startNow") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container grid-list-xs>
      <div class="container bloc-lg">
        <div class="row">
          <v-col class="text-center">
            <div style="color: #398860">
              <h1 class="mg-md tc-mint mb-4">
                {{ $t("saveData") }}
              </h1>
            </div>
            <span class="title my-4">
              {{ $t("saveConferences") }}
            </span>
            <br />
            <span class="title my-4"> {{ $t("save") }}</span>
            <br />
            <span class="title my-4"> {{ $t("record") }}</span>
            <br />
            <span class="title my-4"> {{ $t("bug") }} </span>
            <v-dialog width="500">
              <template v-slot:activator="{ on }">
                <v-btn class="my-4" v-on="on" color="primary" outlined>
                  {{ $t("contact") }}</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{ $t("feedback") }}
                </v-card-title>
                <v-card-text>
                  <br />
                  <v-img
                    :src="require('@/assets/munshareqq.jpg')"
                    max-width="300"
                  >
                  </v-img>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12">
            <v-carousel height="100%">
              <v-carousel-item
                v-for="(item, i) in history"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </div>
      </div>
    </v-container>
    <v-container fluid style="background-color: #dcf1ef">
      <div class="container bloc-lg">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <div style="color: #398860">
              <h1 class="mg-md tc-mint mb-4">
                {{ $t("sessionPro") }}
              </h1>
            </div>
            <span class="title my-4">
              {{ $t("appSupport") }}
            </span>
            <br />
            <span class="title my-4">
              {{ $t("languageSupport") }}
            </span>
            <br />
            <span class="title my-4">
              {{ $t("showLogo") }}
            </span>
            <br />
            <br />
            <v-btn
              color="success"
              outlined
              href="https://download.munshare.com/"
              target="_blank"
              class="mr-2"
            >
              {{ $t("download") }}
            </v-btn>
          </div>
          <div class="col-md-6 align-self-center">
            <div style="color: #398860">
              <h1 class="mg-md tc-mint mb-4">
                {{ $t("munshareEdu") }}
              </h1>
            </div>
            <span class="title my-4">
              {{ $t("homepage") }}
            </span>
            <br />
            <span class="title my-4">
              {{ $t("freeTrial") }}
            </span>
            <br />
            <span class="title my-4">
              {{ $t("subscription") }}
            </span>
            <br />
            <v-dialog width="500">
              <template v-slot:activator="{ on }">
                <v-btn class="my-4" v-on="on" color="primary" outlined>
                  {{ $t("contact") }}</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                </v-card-title>
                <v-card-text>
                  <br />
                  <v-img
                    :src="require('@/assets/munshareqq.jpg')"
                    max-width="300"
                  >
                  </v-img>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-row>
          <v-carousel height="100%" cycle>
            <v-carousel-item
              v-for="(item, i) in pro"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      functions: [
        {
          src:
            "https://file.munshare.com/61e185bb7e935d4a2f87.png/rollcall.png",
        },
        {
          src: "https://file.munshare.com/6011909c30536d5723ba.png/vote.png",
        },
        {
          src: "https://file.munshare.com/7631214cac049347e5aa.png/motion.png",
        },
        {
          src: "https://file.munshare.com/382fca088d891f77738d.png/gsl.png",
        },
        {
          src: "https://file.munshare.com/f302a7794e58ec19daa2.png/mod.png",
        },
      ],
      history: [
        {
          src:
            "https://file.munshare.com/65110a4808939d8db3b6.png/reminder.png",
        },
        {
          src: "https://file.munshare.com/3175b3c6cf6a1f6893cb.png/record.png",
        },
      ],
      pro: [
        {
          src: "https://file.munshare.com/34abb4d0e9ec556af4a3.png/english.png",
        },
        {
          src: "https://file.munshare.com/76a2f201b71a75dea7ac.png/dark.png",
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>


<i18n>
{
  "en": {
    "immediateUse": "Immediate Use",
    "download": "Download Desktop App",
    "yourConferenceApp": "Your MUN Conference App",
    "withBrowser": "Within Browser, Chair Your MUN Conference with Ease",
    "lightWeight": "Lightweight Webapp for Immediate Use",
    "noInstall": "No Need to Install",
    "browserOnly": "Immediate Use on Your Browser(with Chrome for Best Performance)",
    "offLine": "Support All Platforms and Offline Use",
    "satisfyRequirements": "Satisfy Requirements of MUN Conferences",
    "importCountries": "Import Countries Easily, with Templates and Customized Country",
    "speakingManage": "Manage Speaker's List and Time Easily",
    "voteCalculation": "Vote and Roll Call",
    "startNow": "Start Now",
    "languageSupport": "Language Support for English and Chinese",
    "saveData": "Keep tracking your conferences",
    "saveConferences": "Save conferences everytime",
    "save": "Store Conference Records Offline",
    "record": "Save Conference Record",
    "contact": "Contact Us",
    "sessionPro": "Session Pro",
    "subscription": "Subscribe for your budget",
    "feedback": "Thank you for helping Session better",
    "munshareEdu": "Munshare Education",
    "freeTrial": "Three months free trial of Session Pro",
    "homepage": "Your homepage and manage your members",
    "bug": "Find Bugs? Have Good Ideas?",
    "showLogo": "Showcase your Logo，Customize the Theme",
    "pause": "Pause"

  },
  "zh": {
    "immediateUse": "随开随用",
    "download": "下载桌面App",
    "yourConferenceApp": "优雅专业的模联会议主持APP",
    "withBrowser": "只需浏览器，轻松主持你的模联会议",
    "lightWeight": "即开即用的轻量网站APP",
    "noInstall": "任一电脑，无需安装",
    "browserOnly": "在你的浏览器上即开即用（Chrome使用体验最佳)",
    "offLine": "全平台技术支持，离线可用",
    "satisfyRequirements": " 满足模联会议各项需求",
    "importCountries": "轻松添加国家，支持预设委员会和自定义国家",
    "speakingManage": "轻松管理发言名单，记录发言时间",
    "voteCalculation": "点名投票自动计算多数",
    "languageSupport": "中文，英语多语言支持",
    "saveData": "记录你主持过的每一场会议",
    "startNow": "现在开始",
    "saveConferences": "云端储存会议数据，真正记录每一场模联会议💾",
    "save": "离线本地保存，上线后云端自动存储",
    "record": "会议记录时刻保存，方便代表复盘会议",
    "bug": "发现Bug？有需求？",
    "showLogo": "展示会议Logo，订制主题色，更有酷炫黑夜模式😎",
    "contact": "联系我们",
    "munshareEdu": "模时Education",
    "subscription": "订阅式服务，满足你的预算",
    "sessionPro": "专业模联会议，就用Session Pro🥰",
    "freeTrial": "获得3个月免费Session Pro",
    "homepage": " 拥有自己协会主页，管理团队成员",
    "appSupport": " MacOS，Windows桌面端App支持，表现更稳定",
    "feedback": "感谢你让模时Session变的更好",
    "pause": "暂停"
  }
}
</i18n>